.App {
  text-align: center;
  width: 100%;
  minWidth: 200;
  height: 140%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.boton_Languge{
  background:none!important;
  color: #fff;
  border:none;
  padding:0!important;
  /*border is optional*/

  cursor: pointer;
  font-size: 18px;
}
.boton_Languge:hover{
  color: #fff;
  background:none!important;
}
.boton_Languge:focus{
  color: #fff;
  background:none!important;
}

.bodyDownload {
  background: #0f749d;
  background: -webkit-linear-gradient(right, #25d6fb, #0f749d);
  background: -moz-linear-gradient(right, #25d6fb, #0f749d);
  background: -o-linear-gradient(right, #25d6fb, #0f749d);
  background: linear-gradient(to left, #25d6fb, #0f749d);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.piePaginas{
  width: 28%;
  minWidth: 200;
  height: 400px;
}
