@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");
/*single_prising div_area avatar features_info bodyDownload prising_header*/
body {
  font-family: "Poppins", sans-Helvetica;
  font-weight: normal;
  font-style: normal;
}

.bodyDownload {
  background: #fff;
  /*background: -webkit-linear-gradient(right, #f1fdff, #d2e3ec);*/
  /*background: -moz-linear-gradient(right, #f1fdff, #d2e3ec);*/
  /*background: -o-linear-gradient(right, #f1fdff, #d2e3ec);*/
  /*background: linear-gradient(to left, #f1fdff, #d2e3ec);*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.cardPathbook {
  min-width: 370px;
}

.section_title h3 {
  font-size: 46px;
  line-height: 60px;
  font-weight: 300;
  color: #2C2C2C;
  position: relative;
  z-index: 0;
  padding-bottom: 15px;
}

/* Generales uso pupular*/

.botonDescarga {
  float: none;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  minWidth: 200;
  maxWidth: 300;
}
.botonDescargaImg {
  width: 100%;
  minWidth: 200;
  maxWidth: 300;
}
.centrarColumna {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.botonCompraAhora {
  display: inline-block;
  font-size: 18px;
  color: #5DB2FF;
  border: 1px solid #5DB2FF;
  text-transform: capitalize;
  padding: 8px 28px;
  font-weight: 500;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  minWidth: 200;
  maxWidth: 300;

}
.botonCompraAhora:hover {
  background: #5DB2FF;
  color: #fff;

}
.aprobechaAhora{
  background: #0c6ba8;
  display: inline-block;
  font-size: 24px;
  color: #c1e4fb;
  border: 1px solid #073563;
  text-transform: capitalize;
  padding: 8px 28px;
  font-weight: 500;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  minWidth: 200;
  maxWidth: 300;
}
.aprobechaAhora:hover {
  background: #c1e4fb;
  color: #0c6ba8;
}

.bttnOferta{
  background: #f9c118;
  display: inline-block;
  font-size: 24px;
  color: #000;
  border: 1px solid #073563;
  text-transform: capitalize;
  padding: 8px 28px;
  font-weight: 500;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  minWidth: 200;
  maxWidth: 300;
}
.bttnOferta:hover {
  background: #000;
  color: #f9c118;
}

.img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.imgTutorial{
  margin-left: 15%;
  margin-right: 15%;
  width: 80%;
  minWidth: 200;
  maxWidth: 300;
}

.imgOffert{
  margin:auto;
  width: 100%;
  minWidth: 200;
  maxWidth: 300;
  float: none;
  margin-left: auto;
  margin-right: auto;

}
.imagenBarraChica {
  background-image: url(../images/headers/headerChico.png);
}
.barraChica_area {
  background-size: cover;
  background-position: bottom;
  padding: 30px;
  position: relative;
  z-index: 0;
}
@media (max-width: 567px) {
  .barraChica_area {
    padding: 20px;
  }
}

.logoBarraChica{
  margin:auto;
  width: 15%;
  display:block;

}
@media (max-width: 767px) {
  .logoBarraChica {
    margin:auto;
    width: 46%;
    display:block;
  }
}


.avatar {
  height: 180px;
  width: 180px;
  margin:auto;
  display:block;
  border-radius: 50%;
  background-size: 10% auto;
  float: none;
  margin:auto;
}
@media (max-width: 767px) {
  .avatar {
    height: 120px;
    width: 120px;
    margin:auto;
    display:block;
  }
}
.texDerecha{
  text-align:right;
}
.texCenter{
  text-align:center;
}
.texRight{
  text-align:right;
}

.tituloBlanco {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #fff;
  text-shadow: .5px .5px .5px #000;
}
.tituloNegro {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #000;
  text-shadow: .5px .5px .5px #fff;
}
.tituloAmarillo {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #f9c118;
}
.tituloAzul {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #0c6ba8;
}
.tituloAzulClaro {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #c1e4fb;
}
.tituloGris {
  font-family: 'Montserrat-Bold', Helvetica;
  color: #6d6d6e;
}

.subTituloBlanco {
  font-family: 'Montserrat', Helvetica;
  color: #fff;
  font-weight: 500;

}
.subTituloNegro {
  font-family: 'Montserrat', Helvetica;
  color: #000;
  font-weight: 500;
}
.subTituloAmarillo {
  font-family: 'Montserrat', Helvetica;
  color: #f9c118;
  font-weight: 500;
}
.subTituloAzul {
  font-family: 'Montserrat', Helvetica;
  color: #0c6ba8;
  font-weight: 500;
}
.subTituloGris {
  font-family: 'Montserrat', Helvetica;
  color: #6d6d6e;
  font-weight: 500;
}

.txtBlanco {
  font-family: 'Montserrat-Bold', Helvetica;
  font-size: 16px;
  color: #fff;
}
.txtNegro {
  font-family: 'Montserrat-Bold', Helvetica;
  font-size: 16px;
  color: #000;
}
.txtAmarillo {
  font-family: 'Montserrat-Bold', Helvetica;
  font-size: 16px;
  color: #f9c118;
}
.txtAzul {
  font-family: 'Montserrat-Bold', Helvetica;
  font-size: 16px;
  color: #0c6ba8;
}


/* line 1, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios {
  padding-top: 50px;
  padding-bottom: 150px;

}

/* line 4, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios.minus_padding {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
  .areaPrecios {
    padding: 50px 0 20px 0;
  }
}

/* line 10, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .section_title {
  padding-bottom: 90px;
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
  .areaPrecios .section_title {
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
  .areaPrecios .section_title {
    padding-bottom: 50px;
  }
}

/* line 25, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.single_prising {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding-bottom: 40px;
  background: #ecf2f7;
  border-radius: 10px 10px 10px 10px;
}

/* line 29, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising .prising_header {
  padding: 28px 45px;
  border-radius: 10px 10px 0px 0px;

}
/* line 37, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising .prising_header span {
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  line-height: 35px;
}

.areaPrecios .single_prising .prising_header.green_header span {
  color: #fff;
}

/* line 50, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising .prising_header.pink_header {
  background: #68bcf6;
}

/* line 52, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising .prising_header.pink_header span {
  color: #fff;
}

/* line 57, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising ul {
  margin-top: 30px;
  margin-bottom: 30px;

}

/* line 60, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising ul li {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
}

/* line 65, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_prising.scss */
.areaPrecios .single_prising ul li:last-child {
  margin-bottom: 20px;
}


a,
.button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 20, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
a:focus,
.button:focus, button:focus {
  text-decoration: none;
  outline: none;
}

/* line 25, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
a:focus {
  text-decoration: none;
}

/* line 28, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  text-decoration: none;
}

/* line 34, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
a,
button {
  color: #1F1F1F;
  outline: medium none;
}

/* line 52, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
ul {
  margin: 0px;
  padding: 0px;
}

/* line 56, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
li {
  list-style: none;
}

/* line 59, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #4D4D4D;
  margin-bottom: 13px;
  font-family: "Poppins", sans-Helvetica;
}

/* line 68, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

/* line 74, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
*::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 79, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 84, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 89, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 94, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
*:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 99, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 104, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/theme-default.scss */
*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}



/* line 168, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
.areaAzul {
  background: #0c6ba8;
  background: -webkit-linear-gradient(right, #074e7b, #0c6ba8);
  background: -moz-linear-gradient(right, #074e7b, #0c6ba8);
  background: -o-linear-gradient(right, #074e7b, #0c6ba8);
  background: linear-gradient(to left, #074e7b, #0c6ba8);
  background-size: cover;
  background-position: center center;
  padding: 64px 0;
  background-repeat: no-repeat;
}


@media (max-width: 767px) {
  .areaAzul {
    padding: 100px 0;
  }
}
.areaAzul .app_download {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 192, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaAzul .app_download {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 192, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaAzul .app_download {
    text-align: center;
  }
}

/* line 200, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
.areaAzul .app_download img {
  margin-left: 9px;
}

@media (max-width: 767px) {
  /* line 200, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaAzul .app_download img {
    margin: 5px 0;
  }
}




/* line 168, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
.areaTransparente {
  background-size: cover;
  background-position: center center;
  padding: 64px 0;
  background-repeat: no-repeat;
}


@media (max-width: 767px) {
  .areaTransparente {
    padding: 100px 0;
  }
}
.areaTransparente .app_download {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 192, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaTransparente .app_download {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 192, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaTransparente .app_download {
    text-align: center;
  }
}

/* line 200, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
.areaTransparente .app_download img {
  margin-left: 9px;
}

@media (max-width: 767px) {
  /* line 200, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_features.scss */
  .areaTransparente .app_download img {
    margin: 5px 0;
  }
}






.portadasImg img{
  width: 100%;
}

.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer .footer_top {
  padding-top: 117px;
  padding-bottom: 205px;
  background: #1F1F1F;
}

@media (max-width: 767px) {
  /* line 6, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 6, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 18, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

/* line 25, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_title {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 25, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}

/* line 35, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_logo {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}

/* line 45, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p {
  color: #C7C7C7;
}

/* line 48, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #B2B2B2;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

/* line 54, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #B2B2B2;
  font-weight: 400;
}

/* line 57, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #5DB2FF;
  border-bottom: 1px solid #5DB2FF;
}

/* line 63, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #B2B2B2;
}

/* line 67, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #5DB2FF;
  border-bottom: 1px solid #5DB2FF;
}

/* line 71, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

/* line 79, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li {
  color: #C7C7C7;
  font-size: 13px;
  line-height: 42px;
}

/* line 83, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a {
  color: #C7C7C7;
}

/* line 85, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a:hover {
  color: #5DB2FF;
}

/* line 91, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 94, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding-left: 20px;
  font-size: 16px;
  color: #000;
  border: none;
}

/* line 102, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 16px;
  color: #919191;
}

/* line 107, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #5DB2FF;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
}

/* line 121, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_text {
  font-size: 16px;
  color: #BABABA;
}

/* line 127, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .copy-right_text {
  padding-bottom: 30px;
  background: #000000;
}

/* line 130, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .copy-right_text .footer_border {
  padding-bottom: 30px;
}

/* line 133, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right {
  font-size: 13px;
  color: #C7C7C7;
  margin-bottom: 0;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 133, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .copy-right_text .copy_right {
    font-size: 13px;
  }
}

/* line 141, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right a {
  color: #5DB2FF;
}

/* line 146, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  /* line 146, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
  .footer .socail_links {
    margin-top: 30px;
  }
}

/* line 152, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .socail_links ul li {
  display: inline-block;
}

/* line 155, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .socail_links ul li a {
  font-size: 18px;
  color: #C3B2F0;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  background: #565656;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px !important;
  margin-right: 7px;
  color: #FFFFFF;
  line-height: 41px !important;
}

/* line 168, ../../Arafath/CL/CL October/214. App Landing Page/HTML/scss/_footer.scss */
.footer .socail_links ul li a:hover {
  color: #fff !important;
  background: #5DB2FF;
}

.iconoLanding{
  margin:auto;
  width: 60%;
  display:block;

}
@media (max-width: 767px) {
  .iconoLanding {
    margin:auto;
    width: 25%;
    display:block;
  }
}

